<!-- eslint-disable -->
<template>
  <loading v-model:active="isLoading" :is-full-page="fullPage" />
    <div class="row">
      <div class="col-md-5">
        <div class="widget padding-0 white-bg mb-10 br-t-white radius-lr10">
          <div class="white-bg radius-lr10" style="height: 100px"></div>
          <div class="thumb-over">
            <img :src="profilFoto" alt="" class="rounded-circle" width="100">
          </div>
          <div class="padding-20 text-center">
            <p class="lead font-500 margin-b-0">{{ NamaPengguna }}</p>
            <p class="text-muted">{{ 'NPM. ' + NpmMahasiswa }}</p>
            <button class="btn btn-success btn-block" @click="handleEktm">Dowload E-KTM</button>
          </div>
        </div>
        <div class="widget white-bg friends-group clearfix">
          <small class="text-muted">Email address </small>
          <p>{{ emailMahasiswa }}</p>
          <small class="text-muted">Phone</small>
          <p>-</p>
          <small class="text-muted">Alamat</small>
          <p>-</p>
        </div>
      </div>
    </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import { useStore } from 'vuex'
import { getMhs, fotoMahasiswa, downloadEktm } from '@/services/mahasiswa.service'
export default {
  name: 'AdminDashboard',
  components: {
    Loading
  },
  data () {
    const store = useStore()
    const users = store.getters.user
    const namaMhs = users?.nama_lengkap || ''
    const npmMhs = users?.username || ''
    const emailMhs = users?.email || ''
    return {
      userID: users?.id_reg_pd || '',
      NamaPengguna: namaMhs,
      NpmMahasiswa: npmMhs,
      emailMahasiswa: emailMhs,
      profilFoto: '/img/avtar-5.png',
      profilMhs: {},
      loadData: false,
      loadImage: false,
      loadEktm: false,
      fullPage: true
    }
  },
  computed: {
    isLoading: function () {
      const load = !!((this.loadData || this.loadImage || this.loadEktm))
      return load
    }
  },
  created () {
    this.dataMahasiswa()
    this.handleFoto()
  },
  methods: {
    dataMahasiswa () {
      const params = { nipd: this.NpmMahasiswa }
      this.loadData = true
      getMhs(params)
        .then((response) => {
          const row = response.data
          this.profilMhs = row.records
          this.loadData = false
        })
        .catch((error) => {
          this.errorData = (error.response) ? {} : {}
          this.loadData = false
        })
    },
    handleFoto () {
      this.loadImage = true
      fotoMahasiswa({ reg: this.userID })
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response]))
          this.profilFoto = url
          this.loadImage = false
        })
        .catch((error) => {
          this.errorMsg = (error.response) ? {} : {}
          this.loadImage = false
        })
    },
    handleEktm () {
      this.loadEktm = true
      downloadEktm({ reg: this.userID })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'EKTM ' + this.NpmMahasiswa + '.pdf')
          document.body.appendChild(fileLink)

          fileLink.click()
          this.loadEktm = false
        })
        .catch((error) => {
          this.errorMsg = (error.response) ? {} : {}
          this.loadEktm = false
        })
    }
  }
}
</script>
